<template>
	<div v-if="currentWithdrawal">
		<Spacer height size="xl" />
		<div class="pending-withdrawal">
			<div class="pending-withdrawal-left">
				<img :src="require('../../../assets/icons/pending-withdrawal.svg')" />
				<Spacer width size="l" />
				<span>{{ $t('withdrawal.pending-withdrawal') }}</span>
			</div>
			<div>
				<span>{{ formatCurrency($i18n.locale, currentWithdrawal.currency, currentWithdrawal.amount) }}</span>
			</div>
		</div>
		<Spacer height size="xl" />

		<span class="fineprint">
			{{
				$t('withdrawal.you-currently-withdrawal', {
					amount: formatCurrency($i18n.locale, currentWithdrawal.currency, currentWithdrawal.amount),
					method: currentWithdrawal.method || 'default',
				})
			}}
		</span>
	</div>
</template>

<script>
import apiClient from '../../../api';
import Spacer from '@/shared/spacer/Spacer.vue';

export default {
	name: 'PendingWithdrawal',
	components: {
		Spacer,
	},
	data() {
		return {
			currentWithdrawal: null,
		};
	},
	async beforeMount() {
		await this.fetchCurrentWithdrawal();
	},
	methods: {
		async fetchCurrentWithdrawal() {
			const response = await apiClient.currentWithdrawal();
			this.currentWithdrawal = response.data.withdrawal;
		},
		formatCurrency(locale, currency, amount) {
			return Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount);
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/mixin';

.fineprint {
	color: #a3a3a5;
	margin-top: 16px;
	font-size: 0.9rem;
}

.pending-withdrawal {
	@include card-highlighted;
	border: none;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&-left {
		display: flex;
		align-items: center;
	}
}
</style>
